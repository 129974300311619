<template>
  <v-container>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :show="segundosMensajeActualizacion"
              dismissible
              :variant="mensaje.variant"
              @dismissed="segundosMensajeActualizacion = 0"
              @dismiss-count-down="actualizarSegundosMensajeActualizacion"
            >
              {{ mensaje.texto }}
            </b-alert>
            <div class="d-flex">
              <div class="card-title col-auto me-auto">Nombres Documento</div>
              <button
                v-if="rolModuloPermiso.escritura"
                class="btn btn-primary col-auto"
                @click="abrirModalNuevo"
              >
                <i class="mdi mdi-plus me-1"></i>
                Nuevo
              </button>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select
                      class="form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Buscar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                id="tabla-nombre-documento"
                class="datatables"
                :items="nombreDocumentos"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                select-mode="single"
                selectable
                @filtered="onFiltered"
                :busy="nombreDocumentosCargando"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template
                  #cell(accion)="data"
                  v-if="rolModuloPermiso.escritura"
                >
                  <i
                    class="bx bx-edit h3 text-primary pointer me-2"
                    @click="abrirModalEditar(data.item)"
                  ></i>
                  <i
                    class="bx bx-trash h3 text-primary pointer"
                    @click="eliminarNombreDocumentoLocal(data.item)"
                  ></i>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="mostrarModal"
      title="Agregar Documento"
      hide-footer
      size="lg"
    >
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoDocumento">Área Responsable</label>
              <multiselect
                v-model="$v.form.tipoDocumento.$model"
                :options="tipoDocumentos"
                placeholder="Seleccionar Área Responsable"
                value="tipo_documento_id"
                label="nombre_tipo_documento"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoDocumento.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoDocumento.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="nombreDocumento">Nombre Documento</label>
              <input
                id="nombreDocumento"
                v-model="$v.form.nombreDocumento.$model"
                type="text"
                class="form-control"
                placeholder="Nombre Documento"
                :class="{
                  'is-invalid': submitted && $v.form.nombreDocumento.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.nombreDocumento.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="visibilidad">Visibilidad</label>
              <multiselect
                v-model="$v.form.visibilidad.$model"
                :options="visibilidades"
                placeholder="Seleccionar Visibilidad"
                value="value"
                label="label"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.visibilidad.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.visibilidad.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button
            v-if="rolModuloPermiso.escritura"
            type="submit"
            variant="success"
            class="ms-1"
            >Guardar</b-button
          >
        </div>
      </form>
    </b-modal>
  </v-container>
</template>
<script>
//import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { tipoDocumentoMethods, tipoDocumentoComputed } from "@/state/helpers";
import {
  nombreDocumentoMethods,
  nombreDocumentoComputed,
} from "@/state/helpers";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
  mixins: [validationMixin],
  page: {
    title: "Nombres Documento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    //Layout,
    PageHeader,
    Multiselect,
  },

  data() {
    return {
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      lugarInstalacion: {},
      proyectos: [],
      title: "Nombres Documento",
      items: [
        {
          text: "Mantenedores",
        },
        {
          text: "Documentos",
        },
        {
          text: "Nombres Documento",
          active: true,
        },
      ],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "Proyecto",
      sortDesc: false,
      fields: [
        {
          key: "Accion",
          sortable: false,
        },
        {
          key: "nombre_documento_id",
          sortable: true,
        },
        {
          key: "nombre_documento",
          sortable: true,
        },
        {
          key: "visibilidad",
          sortable: true,
        },
        {
          key: "nombre_tipo_documento",
          label: "Área Responsable",
          sortable: true,
        },
      ],
      guardarCerrar: true,
      nombreDocumentoSeleccionado: {},
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      nombreDocumentos: [],
      tipoDocumentos: [],
      nombreDocumentosCargando: false,
      tipoDocumentosCargando: false,
      visibilidades: [
        { value: "Publico", label: "Publico" },
        { value: "Privado", label: "Privado" },
      ],
      form: {
        nombreDocumento: null,
        tipoDocumento: null,
        visibilidad: null,
      },
      rolModuloPermiso: {},
    };
  },
  validations: {
    form: {
      nombreDocumento: { required },
      tipoDocumento: { required },
      visibilidad: { required },
    },
  },
  mounted() {
    this.obtenerNombreDocumentosLocal();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...nombreDocumentoMethods,
    ...tipoDocumentoMethods,
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo() {
      this.resetForm();
      this.obtenerTipoDocumentosLocal();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
    },
    abrirModalEditar(datos) {
      this.nombreDocumentoSeleccionado = datos;
      this.obtenerTipoDocumentosLocal();
      this.resetForm();

      this.$v.form.nombreDocumento.$model = datos.nombre_documento;
      this.$v.form.visibilidad.$model = {
        value: datos.visibilidad,
        label: datos.visibilidad,
      };

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerNombreDocumentosLocal() {
      this.nombreDocumentosCargando = true;
      this.obtenerNombreDocumentos()
        .then((res) => {
          this.nombreDocumentos = res.body;
          this.totalRows = this.nombreDocumentos.length;
          this.nombreDocumentosCargando = false;
        })
        .catch((error) => {
          this.nombreDocumentosCargando = false;
        });
    },
    obtenerTipoDocumentosLocal() {
      this.tipoDocumentosCargando = true;
      this.obtenerTipoDocumentos()
        .then((res) => {
          this.tipoDocumentos = res.body;
          if (this.esEditar) {
            this.$v.form.tipoDocumento.$model = {
              tipo_documento_id:
                this.nombreDocumentoSeleccionado.tipo_documento_id,
              nombre_tipo_documento:
                this.nombreDocumentoSeleccionado.nombre_tipo_documento,
            };
          }
          this.tipoDocumentosCargando = false;
        })
        .catch((error) => {
          this.tipoDocumentosCargando = false;
        });
    },
    submit(e) {
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let nombre_documento_id = "";
      if (this.esEditar) {
        nombre_documento_id =
          this.nombreDocumentoSeleccionado.nombre_documento_id;
      }

      const nombre_documento = this.$v.form.nombreDocumento.$model;
      const visibilidad = this.$v.form.visibilidad.$model.value;
      const tipo_documento_id =
        this.$v.form.tipoDocumento.$model.tipo_documento_id;

      let nuevoNombreDocumento = {
        nombre_documento_id,
        nombre_documento,
        visibilidad,
        tipo_documento_id,
      };

      if (this.esEditar) {
        this.actualizarNombreDocumento(nuevoNombreDocumento)
          .then((res) => {
            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto =
                "Nombre Documento actualizado correctamente!!!";
              this.mostrarModal = false;
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerNombreDocumentosLocal();
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModal = false;
            this.segundosMensajeActualizacion = this.segundos;
          });
      } else {
        this.crearNombreDocumento(nuevoNombreDocumento)
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Nombre Documento creado correctamente!!!";
              this.mostrarModal = false;
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "El tipo documento ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerNombreDocumentosLocal();
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
          });
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        nombreDocumento: null,
        tipoDocumento: null,
        visibilidad: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    eliminarNombreDocumentoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarNombreDocumento(item.nombre_documento_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto =
                    "Nombre Documento eliminado correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerNombreDocumentosLocal();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style>
.pointer {
  cursor: pointer !important;
}

#tabla-nombre-documento td,
#tabla-nombre-documento th {
  white-space: nowrap !important;
}
#tabla-nombre-documento th div {
  margin-right: 10px !important;
}
</style>
